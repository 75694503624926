<template>
  <div class="invite">
    <subtitle>{{ $t('accountBooks.invite.title') }}</subtitle>

    <div v-if="error" class="notification is-danger">
      {{ error }}
    </div>

    <form @submit.prevent="invite">
      <input-group
        type="email"
        :label="$t('accountBooks.invite.form.email')"
        name="inviteEmail"
        v-model="email"
        required
      />

      <button type="submit" class="button is-primary">{{ $t('accountBooks.invite.form.submitButton') }}</button>
    </form>
  </div>
</template>

<script>
import Subtitle from '../basic/Subtitle'
import InputGroup from '../basic/InputGroup'

export default {
  name: 'edit-transaction',
  components: {
    InputGroup,
    Subtitle
  },
  data () {
    return {
      email: null,
      error: null
    }
  },
  methods: {
    async invite () {
      try {
        await this.$store.dispatch('accountBooks/invite', this.email)

        this.$router.push('/')
      } catch (error) {
        console.log(error)
        this.error = error.response.data.message
      }
    }
  }
}
</script>

<style scoped>

</style>
